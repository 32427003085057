import React, {useMemo} from "react";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import PageActions from "../../../base/PageActions";
import ContentBox from "../../../../components/content-box/ContentBox";
import '../../../styles/Common.scss';

interface VixyStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const VixyStreamPage = (props: VixyStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Vixy - Chase the Fox`,
        description: "Vixy is Chase's assistant, here to help with all of his cartoonish antics!",
        keywords: ["twitch", "twitch channel", "stream", "twitch stream", "chasetheredfox", "vixy", "vixy foxworth",
            "stream buddy", "ai buddy", "tts buddy", "ai assistant"],
        imgSrc: `chase/stream/vixy_chibi.png`,
        url: `stream/vixy`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox header="Meet Vixy, Chase's stream assistant!">
                <div className="stream-about">
                    <img className="width-30-percent"
                         src={page.image().getCdnImageSrc(`chase/stream/vixy_chibi.png`)}
                         alt="A pink fox with short blond hair and glasses. She carries a notebook under her arm and
                              appears to be speaking to the viewer"
                         title="Vixy Foxworth"/>
                    <p>
                        Vixy is Chase's stream assistant, there to help out with a number of tasks. She's quite bookish
                        and often times annoyed by Chase. Chase will often come up with a ridiculous demand or try to
                        tease her, so she is often annoyed by his antics. As such, you'll often hear her tease Chase
                        back or threaten to turn him into something that will keep him out of her hair for a while.
                        Whether she's sitting in the background listening to the chat or stepping forward to keep Chase
                        in check, she adds to the chaotic nature of Chase's broadcasts.
                    </p>
                    <p>
                        Her personality and her own chaotic nature will sometimes lead her to saying the craziest
                        things! Check out these
                        shorts from <a href="https://www.youtube.com/@chasetheredfoxttv"
                                       target="_blank"
                                       rel="noopener noreferrer">Chase's YouTube channel</a> to see her in action!
                    </p>
                    <div className="video-container width-80-percent center">
                        <iframe width="320" height="568" src="https://www.youtube.com/embed/E2Jyz95tVRc"
                                title="Vixy Reminds You to Boop! #twitchclips #furry #vtuber"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                        <iframe width="320" height="568" src="https://www.youtube.com/embed/FE4YNQ-MsYQ"
                                title="Certified Fox Moment: Sandwich #funny #games #vtuber" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </ContentBox>
        </>
    );
}

export default VixyStreamPage;