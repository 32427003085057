import React, {useMemo} from "react";
import PageActions from "../../../base/PageActions";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import "../../../styles/Common.scss";

interface MarioStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const MarioStreamPage = (props: MarioStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Mario - Chase the Fox`,
        description: "Learn about Chase's stream in terms of Mario games and art!",
        keywords: ["twitch", "stream info", "mario", "super mario", "games", "gaming", "chasetheredfox"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/mario`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox header="Yahoo! Let's-a go!">
                <div>
                    <img className="width-50-percent block"
                         src={page.image().getCdnImageSrc('chase/stream/mario/mario_screenshot.png')}
                         alt="A screenshot from a Mario game"
                         title="Mario Time!"/>
                    <p>
                        I grew up with Mario games, and I love to play them on stream once in a while. Whether I'm
                        diving head-first into the newest Mario title, sharing a retro classic with my community, or
                        tackling the toughest levels from my viewers and Mario Maker troll creators, there will be
                        plenty of jumping and Goomba-stomping to come when one of these streams starts up!
                    </p>
                    <p>
                        Want a taste of some of the craziness to anticipate in some of the streams? Check out these
                        shorts from <a href="https://www.youtube.com/@chasetheredfoxttv"
                                       target="_blank"
                                       rel="noopener noreferrer">Chase's YouTube channel</a> below!
                    </p>
                    <div className="video-container width-80-percent center">
                        <iframe width="320" height="568" src="https://www.youtube.com/embed/Dt_Nx5ApWbs"
                                title="The Boss Doesn&#39;t Have the Key!? #mariomaker2 #twitch #twitchclips"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                        <iframe width="320" height="568" src="https://www.youtube.com/embed/tFymQkfgF6Q"
                                title="Certified Fox Moment: Easy Level #funny #games #vtuber"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </ContentBox>
        </>
    );
}

export default MarioStreamPage;