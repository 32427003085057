import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import PageActions from "../../../base/PageActions";
import ContentBox from "../../../../components/content-box/ContentBox";
import '../../../styles/Common.scss';

interface BluerockBayPageProps {
    dependencies: Dependencies,
    env: Environment
}

const BluerockBayPage = (props: BluerockBayPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Bluerock Bay - Chase the Fox`,
        description: "Chase streams from a mysterious location known as Bluerock Bay!",
        keywords: ["twitch", "twitch channel", "stream info", "twitch stream", "chasetheredfox", "bluerock bay"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/bluerock`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox header="Welcome to Bluerock Bay!">
                <div className="stream-about">
                    <img className="width-50-percent"
                         src={page.image().getCdnImageSrc(`chase/stream/bluerock_bay.png`)}
                         alt="A calm bay on a sunny day. On the left is a rocky hill coated on top in trees up until
                              a rock wall that meets a sandy beach. Scattered about the edge of the ocean are gigantic
                              blue gemstones that jut out from the ocean's surface and the rock wall. On the opposite
                              end is a cavern that appears to tbe entrypoint into the bay, besparkled with blue and
                              giving off a faint glow. The rest of the scene shows the beach. The rock wall and this
                              beach have faint sparkles from the blue stones."
                         title="The location known as Bluerock Bay"/>
                    <p>
                        Chase's streams take place in Bluerock Bay. This location, sitting
                        in <NavLink to="/world/geography">the Escanina Islands of Casarok</NavLink>, is named for the
                        enormous blue crystals that protrude from the walls of the bay and the surrounding ocean. As
                        these gems are weathered and eroded, they leave the water and the rock walls a sparkling
                        sapphire color. This locale is known for its lustrous beauty, the diverse vegetation, and the
                        calm waters that filter into the bay.
                    </p>
                    <p>
                        A lone bungalow rests in this small bay along the waters. This abode carries a high-speed
                        internet connection (somehow) so this is where you'll often find Chase doing his usual
                        shenanigans whether its with his drawing tablet or variety of gaming consoles. Sometimes, there
                        will be strange anomalies occurring in the background, which adds to the enigmatic nature of
                        the bay.
                    </p>
                    <p>
                        What is the mystery behind Bluerock Bay? The secrets will be unveiled over time, so stay tuned
                        in to my channel at <a href="https://stream.chasethefox.com"
                                               target="_blank"
                                               rel="noopener noreferrer">https://stream.chasethefox.com</a> or clicking
                        my icon below!
                    </p>
                    <div>
                        <a className="stream-link"
                           href="https://stream.chasethefox.com"
                           target="_blank"
                           rel="noopener noreferrer">
                            <img src={page.image().getCdnImageSrc(`chase/assets/chase_twitch_icon.png`)}
                                 alt="Chase's Twitch profile icon"
                                 title="Come join the Fox Fam!"/>
                        </a>
                    </div>
                </div>
            </ContentBox>
        </>
    );
}

export default BluerockBayPage;