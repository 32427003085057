import React, {useEffect, useRef, useState} from "react";
import {Params, useNavigate, useParams} from "react-router-dom";
import ComicPageView from "../../../../../components/comic-page/ComicPageView";
import ComicControls from "../../../../../components/comic-controls/ComicControls";
import ComicCommentary from "../../../../../components/comic-commentary/ComicCommentary";
import ComicMetadata from "../../../../../model/ComicMetadata";
import Dependencies from "../../../../../utilities/Dependencies";
import Environment from "../../../../../services/interfaces/Environment";
import ContentBox from "../../../../../components/content-box/ContentBox";
import PageActions from "../../../../base/PageActions";
import '../../../../styles/Common.scss';
import '../ComicPage.scss';

interface ComicDisplayPageProps {
    dependencies: Dependencies,
    env: Environment
}

interface ComicDisplayPageParams extends Params {
    comic: string,
    chapter?: string,
    page?: string
}

interface ComicDisplayPageState {
    comic?: string,
    chapter?: number,
    page?: number
}

interface ComicDisplayPageResult {
    allPages: {[page: number] : ComicMetadata},
    maxPage: number
}

const ComicDisplayPage = (props: ComicDisplayPageProps) => {

    const params = useParams<ComicDisplayPageParams>();

    const [state, setState] = useState<ComicDisplayPageState>({
        comic: params.comic,
        chapter: params.chapter ? +params.chapter : undefined,
        page: params.page ? +params.page : undefined
    });

    const page: PageActions = new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `${props.dependencies.namingService.capitalizeName(state.comic || params.comic || '')} ${state.chapter || params.chapter}-${state.page || params.page} - Chase the Fox`,
        description: `Page ${params.page}, Chapter ${params.chapter} of ${props.dependencies.namingService.capitalizeName(params.comic || '')}, story and art by Chase the Fox!`,
        keywords: ["comic", "webcomic", params.comic || '',
            props.dependencies.namingService.capitalizeName(params.comic || '').toLowerCase()],
        imgSrc: `chase/comic/talechasers/tale_chasers_logo.png`,
        url: `comics/${params.comic}/${params.chapter}/${params.page}`
    });
    const [isLoading, setLoading] = useState<boolean>(true);
    const [hasFetchedResult, setFetchedResult] = useState<boolean>(false);

    const metadata = useRef<ComicDisplayPageResult>();

    const navigateTo = useNavigate();

    useEffect(() => {
        let comic = '';
        let chapter = -1;

        if (!params.comic || params.comic.trim().length === 0
                || !params.chapter || params.chapter.trim().length === 0 || +params.chapter < 0) {
            navigateTo("/error", {replace: true});
        } else {
            comic = params.comic;
            chapter = +params.chapter;
        }

        page.http().getComicChapterPages(comic, chapter)
            .then(response => {
                if (!response) {
                    navigateTo("/error", {replace: true});
                } else {
                    metadata.current = {
                        allPages: response,
                        maxPage: +Object.keys(response).reduce((a, b) => ''+Math.max(+a, +b))
                    };
                }
            })
            .catch(() => navigateTo("/error", {replace: true}))
            .finally(() => setFetchedResult(true));
    }, [state]);

    const goToPage = (page: number): void => {
        setLoading(true);
        setState({...state, page: page});
        return navigateTo(`/comics/${state.comic || params.comic}/${state.chapter || params.chapter}/${state.page}`);
    }

    const getCurrentPageMetadata = (): ComicMetadata => {
        let page = params.page ? +params.page : -1;
        if (metadata.current && page >= 0 && Object.keys(metadata.current.allPages).includes(''+page)) {
            return metadata.current!.allPages[page];
        }

        return {commentary: "", imageId: "", page: page};
    }

    return page.generateMetaContent(
        <>
            <ContentBox key={`comic-${params.comic}-${params.chapter}-${params.page}`}>
                <img className="comic-header"
                     src={page.image().getCdnImageSrc(`chase/comic/talechasers/tale_chasers_logo.png`)}
                     alt="Tale Chasers"
                     title="Art and story by Chase the Fox"/>
                <ComicControls currentPage={params.page ? +params.page : 0}
                               maxPage={metadata.current ? metadata.current.maxPage : 0}
                               goTo={(page) => goToPage(page)}/>
                {hasFetchedResult
                    ? <ComicPageView fullImageSrc={page.image().getCdnImageSrc(
                    `comics/talechasers/${params.chapter}/${getCurrentPageMetadata().imageId}.png`,
                    'desktop')}
                               webImageSrc={page.image().getCdnImageSrc(
                                   `comics/talechasers/${params.chapter}/${getCurrentPageMetadata().imageId}.png`)}
                               loading={isLoading}
                               onLoad={() => setLoading(false)}/>
                        : <></>}
                <ComicCommentary comment={getCurrentPageMetadata().commentary}/>
                <ComicControls currentPage={params.page ? +params.page : 0}
                               maxPage={metadata.current ? metadata.current.maxPage : 0}
                               goTo={(page) => goToPage(page)}/>
            </ContentBox>
        </>
    );
}

export default ComicDisplayPage;