import React from "react";
import Dependencies from "../../utilities/Dependencies";
import Environment from "../../services/interfaces/Environment";
import HttpService from "../../services/HttpService";
import ImageService from "../../services/ImageService";
import DeviceService from "../../services/DeviceService";
import SeoService from "../../services/SeoService";
import NamingService from "../../services/NamingService";
import HtmlService from "../../services/HtmlService";
import {Helmet} from "react-helmet";

interface PageActionProps {
    dependencies: Dependencies,
    env: Environment,
    title: string,
    description: string,
    keywords: string[],
    url: string,
    imgSrc: string
}

class PageActions {

    private static readonly _common_keywords: string[] = ["chase the fox", "furry", "furry art",
        "furry oc", "furry streamer", "furry artist", "furry website", "chasetheredfox"];

    private readonly props: PageActionProps;

    constructor(props: PageActionProps) {
        this.props = props;
    }

    public getTitle(): string {
        return this.props.title;
    }

    public getDescription(): string {
        return this.props.description;
    }

    public getKeywords(): string {
        return PageActions._common_keywords.concat(this.props.keywords).filter(keyword => keyword).join(',');
    }

    public getUrl(): string {
        let fullUrl = this.props.url;
        if (!fullUrl.startsWith(this.props.env.targetClient)) {
            fullUrl = this.props.env.targetClient + ((fullUrl.startsWith('/')) ? fullUrl.substring(1) : fullUrl);
        }

        return fullUrl;
    }

    public getImgSrc(): string {
        return this.image().getCdnImageSrc(this.props.imgSrc, 'thumbnail');
    }

    public http(): HttpService {
        return this.props.dependencies.httpService;
    }

    public image(): ImageService {
        return this.props.dependencies.imageService;
    }

    public device(): DeviceService {
        return this.props.dependencies.deviceService;
    }

    protected seo(): SeoService {
        return this.props.dependencies.seoService;
    }

    public naming(): NamingService {
        return this.props.dependencies.namingService;
    }

    public html(): HtmlService {
        return this.props.dependencies.htmlService;
    }

    // TODO: Make generalized for different site pages
    private getRssFeed(): string {
        return `${this.props.env.targetClient.endsWith('/')
            ? this.props.env.targetClient
            : this.props.env.targetClient + '/'}v1/rss/feeds/chase`;
    }

    public generateMetaContent(pageContent: JSX.Element): JSX.Element {
        return (
            <>
                <Helmet>
                    <title>{this.getTitle()}</title>
                    <link rel="alternate" type="application/rss+xml" title="Chase's Site Updates" href={this.getRssFeed()}/>
                    <link id="canonical-link" rel="canonical" href={this.getUrl()}/>
                    <meta property="description" content={this.getDescription()}/>
                    <meta property="keywords" content={this.getKeywords()}/>
                    <meta property="og:url" content={this.getUrl()}/>
                    <meta property="og:site_name" content="Chase the Fox"/>
                    <meta property="og:title" content={this.getTitle()}/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image" content={this.getImgSrc()}/>
                    <meta property="og:description" content={this.getDescription()}/>
                    <meta name="twitter:card" property="twitter:card" content="summary"/>
                    <meta name="twitter:creator" property="twitter:creator" content="@ChaseTheRedFox"/>
                    <meta name="twitter:title" property="twitter:title" content={this.getTitle()}/>
                    <meta name="twitter:image" property="twitter:image" content={this.getImgSrc()}/>
                    <meta name="twitter:image:src" property="twitter:image:src" content={this.getImgSrc()}/>
                    <meta name="twitter:description" property="twitter:description" content={this.getDescription()}/>
                </Helmet>
                {pageContent}
            </>
        );
    }
}

export default PageActions;