import React, {useMemo} from "react";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import ImageLink from "../../../../components/image-link/ImageLink";
import PageActions from "../../../base/PageActions";
import "../../../styles/Common.scss";
import "./CharityStreamPage.scss";

interface CharityStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const CharityStreamPage = (props: CharityStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Charities - Chase the Fox`,
        description: `Chase supports a variety of charities on his stream and runs donation drives!`,
        keywords: ["twitch", "stream info", "chasetheredfox", "charity", "donations"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/charities`
    }), [props]);

    return (
        <>
            <ContentBox header="Help me support these great causes!">
                <div>
                    <p>
                        I support various charities as a part of my efforts to do something for the greater good
                        and foster a welcoming environment on the stream. Donation drives happen every so often to
                        help pull in money for some great causes. Together, the Fox Fam and I have brought in
                        hundreds of dollars for these charities and perhaps even more in the future!
                    </p>
                    <p>
                        To learn more about our efforts and the amount of money raised during these in-stream
                        donation drives, click on one of the logos below!
                    </p>
                    <ul className="charity-list">
                        <li>
                            <ImageLink imgSrc={page.image().getCdnImageSrc('chase/stream/charities/als_logo.png')}
                                       linkUrl="als"
                                       linkName="ALS United"/>
                        </li>
                        <li>
                            <ImageLink imgSrc={page.image().getCdnImageSrc('chase/stream/charities/go_logo.png')}
                                       linkUrl="go"
                                       linkName="Gamers Outreach"/>
                        </li>
                        <li>
                            <ImageLink imgSrc={page.image().getCdnImageSrc('chase/stream/charities/hcf_logo.png')}
                                       linkUrl="kka"
                                       linkName="Hawaii Community Foundation"/>
                        </li>
                    </ul>
                </div>
            </ContentBox>
        </>
    );
}

export default CharityStreamPage;