import SiteTheme from "../../model/SiteTheme";
import NavigationSet from "../../model/NavigationSet";

const _chase_links: NavigationSet = {
    Home: '/',
    Story: {
        Comic: '/comics/talechasers',
        Characters: '/characters',
        World: '/world'
    },
    Stream: {
        About: '/stream',
        "Bluerock Bay": '/stream/bluerock',
        "Vixy": '/stream/vixy',
        "Pokemon": '/stream/pokemon',
        Mario: '/stream/mario',
        Charities: '/stream/charities',
    },
    Art: {
        Commissions: '/commissions',
        Gallery: '/gallery',
        "Telegram Stickers": '/telegram-stickers',
        Shop: '/shop',
    },
    Fun: {
        "Fursona Generator": '/fursona-generator',
        "TF Generator": '/tf-generator'
    },
    "Site Info": {
        Contact: '/contact',
        "Patch Notes": '/patch-notes'
    },
    Otherworlds: {
        Caudalglen: '/cga'
    }
};

const ChaseTheme: SiteTheme = {
    bannerImage: `https://cdn.chasethefox.com/m/chase/theme/logo.png`,
    bannerImageCircular: true,
    bannerTitle: `https://cdn.chasethefox.com/m/chase/theme/title.png`,
    bannerTitleAlt: "Chase the Fox",
    navigationLinks: _chase_links,
    fullReloadLinks: ['/cga']
};

export default ChaseTheme;