import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import Environment from "../../../services/interfaces/Environment";
import Dependencies from "../../../utilities/Dependencies";
import ContentBox from "../../../components/content-box/ContentBox";
import '../../styles/Common.scss';
import './StreamInfoPage.scss';
import PageActions from "../../base/PageActions";

interface StreamInfoPageProps {
    dependencies: Dependencies,
    env: Environment
}

const StreamInfoPage = (props: StreamInfoPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Chase's Twitch Channel - Chase the Fox`,
        description: "Information on Chase's streaming channel and where to find his cartoonish antics!",
        keywords: ["twitch", "twitch channel", "stream info", "twitch stream", "chasetheredfox"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox header="You all know me! My name is Chase, the foxy sensation that's changin' the nation!">
                <div className="stream-about">
                    <p>
                        I stream several nights a week over on my Twitch channel. I'm a variety streamer doing a mix of
                        art and games. Recurring favorites on the stream include <NavLink to="mario">Mario</NavLink>
                        , <NavLink to="pokemon">Pokemon</NavLink>, and various games with the community (like Jackbox
                        Party Packs or <NavLink to="sar">Super Animal Royale</NavLink>). Plenty of different games are
                        played on the stream, and you'll often find me interacting with chat in some way.
                    </p>
                    <img src={page.image().getCdnImageSrc(`chase/stream/fox_coin.png`)}
                         alt="An orange coin emblazoned with a fox face"
                         title="Fox Coin"/>
                    <p>
                        During the stream, viewers can earn Fox Coins (my version of channel points) to redeem
                        various interactions with the stream. A few coins will make me wiggle my ears or eat some
                        chimkin. A lot of coins saved up can change my appearance, be it clothes or a whole new
                        form entirely, or even earn you a free sketch!
                    </p>
                    <p>
                        Want to know more? You can check out the channel by going
                        to <a href="https://stream.chasethefox.com"
                              target="_blank"
                              rel="noopener noreferrer">https://stream.chasethefox.com</a> or clicking
                        my icon below!
                    </p>
                    <div>
                        <a className="stream-link"
                           href="https://stream.chasethefox.com"
                           target="_blank"
                           rel="noopener noreferrer">
                            <img src={page.image().getCdnImageSrc(`chase/assets/chase_twitch_icon.png`)}
                                 alt="Chase's Twitch profile icon"
                                 title="Come join the Fox Fam!"/>
                        </a>
                    </div>
                </div>
            </ContentBox>
        </>
    );
}

export default StreamInfoPage;