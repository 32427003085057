import React, {useMemo} from "react";
import {NavLink} from "react-router-dom";
import PageActions from "../../../base/PageActions";
import Dependencies from "../../../../utilities/Dependencies";
import Environment from "../../../../services/interfaces/Environment";
import ContentBox from "../../../../components/content-box/ContentBox";
import "../../../styles/Common.scss";
import "./PokemonStreamPage.scss";

interface PokemonStreamPageProps {
    dependencies: Dependencies,
    env: Environment
}

const PokemonStreamPage = (props: PokemonStreamPageProps) => {

    const page: PageActions = useMemo(() => new PageActions({
        dependencies: props.dependencies,
        env: props.env,
        title: `Pokemon - Chase the Fox`,
        description: "Learn about Chase's stream in terms of Pokemon games and art!",
        keywords: ["twitch", "stream", "pokemon", "pokemon tcg pocket", "pokemon trading card game pocket",
            "gaming", "chasetheredfox"],
        imgSrc: `chase/assets/chase_twitch_icon.png`,
        url: `stream/pokemon`
    }), [props]);

    return page.generateMetaContent(
        <>
            <ContentBox header="To become the very best like no one ever was.">
                <div>
                    <img src={page.image().getCdnImageSrc('chase/stream/pokemon/pokemon_logo.png')}
                         alt="The Pokemon logo"
                         title="Gotta catch 'em all!"
                         className="width-30-percent block"/>
                    <p>
                        I stream a good amount of Pokemon games from spin-offs to the mainline series. It's
                        mostly casual gameplay, but I have strong knowledge of competitive team-building and have
                        taken part in matches on the ranked ladder. I known what I'm doing (even if it doesn't seem
                        that way most of the time.) Even when I'm not streaming one of the games, I may be doing
                        some related art or holding a special event.
                    </p>
                    <p>
                        During Pokemon Scarlet streams, I generally hold my gym challenge for viewers! The
                        Casarokan Gym Challenge is built as if my world were instead a region in the Pokemon world.
                        Each of the 12 magical elements in Chase's world are represented here: 8 gyms for the
                        non-divine elements and 4 elite for the
                        divine. <NavLink to="./gym-challenge">To check the rules and players' status for the Casarokan
                        Gym Challenge, check out the gym section here!</NavLink>
                    </p>
                    <p>
                        So what kinda trainer is Chase? Check out these shorts
                        from <a href="https://www.youtube.com/@chasetheredfoxttv"
                                       target="_blank"
                                       rel="noopener noreferrer">Chase's YouTube channel</a> below to find out!
                    </p>
                    <div className="video-container width-80-percent center">
                        <iframe width="320" height="568" src="https://www.youtube.com/embed/bS5oX05m9OE"
                                title="PMD Team Redeemed! #pokemon #pokemonscarletandviolet #themeteam"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                        <iframe width="320" height="568" src="https://www.youtube.com/embed/gR8RM372B1o"
                                title="Where are you, Ivysaur!? #funny #vtuber #gaming"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                referrerPolicy="strict-origin-when-cross-origin"
                                allowFullScreen></iframe>
                    </div>
                </div>
            </ContentBox>
        </>
    );
}

export default PokemonStreamPage;